import Vue from 'vue';
import App from './App.vue';
import router from './router';
import 'normalize.css/normalize.css';
import '@/styles/index.scss';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
  navigator.userAgent
);

Vue.use(ElementUI);

var querys = getRouterParams()
function getRouterParams() {
  let href = window.location.href;
  let query = href.substring(href.indexOf('?') + 1);
  let vars = query.split("&");
  let obj = {}
  for (var i = 0; i < vars.length; i++) {
    let pair = vars[i].split("=");
    obj[pair[0]] = decodeURIComponent(pair[1]) 
  }
  return obj;
}
if(querys && querys.deviceInfoStr){
  sessionStorage.setItem('deviceInfoStr',querys.deviceInfoStr);
  sessionStorage.setItem('loginInfoStr',querys.loginInfoStr);
  sessionStorage.setItem('verStr',querys.verStr);
  sessionStorage.setItem('uid',querys.uid);
  sessionStorage.setItem('gameEnvName',querys.gameEnvName);
  if(querys.fromWhere){
    sessionStorage.setItem('fromWhere',querys.fromWhere);
  }else{
    if(sessionStorage.getItem('fromWhere')){
      sessionStorage.removeItem('fromWhere');
    }
  }
}

router.beforeEach((to, from, next) => {
  if (isMobile && to.meta.type !== 'mobile') {
    next('/m_home');
  }

  if (!isMobile && to.meta.type !== 'pc') {
    next('/home');
  }

  next();
});

Vue.config.productionTip = false;

new Vue({
  router,
  render: h => h(App),
}).$mount('#app');
