<template>
    <div  class="guide-popup-wrapper">
      <div class="popup-content">
        <image class="tip-jump" mode="aspectFit" src="@static/unable-jump.png" />
        <div class="text first">
          1.请用浏览器打开此页面
        </div>
        <div class="text">
          2.复制此链接用浏览器打开可跳转
        </div>
        <div class="link-content" id="linkContent">
          <!-- https://work.weixin.qq.com/kfid/kfca1490bb6d0ac7beb -->
          {{ copyText }}
        </div>
        <div id="btn" class="copy-btn" data-clipboard-action="copy" data-clipboard-target="#linkContent" @click="copyServiceUrlHandle">
          <!-- <image class="btn-icon" src="@static/copy-b-icon.png" /> -->
          复制
        </div>
      </div>
    </div>
  </template>
  
<script>
import Clipboard from 'clipboard';
  export default {
      props: {
          value: {
              type: Boolean,
              default: false
          }
      },
      data () {
          return {
              serviceUrl: 'https://work.weixin.qq.com/kfid/kfca1490bb6d0ac7beb',
              copyText:window.location.href
          };
      },
      computed: {
        //   visibleModel: {
        //       get () {
        //           return this.value;
        //       },
        //       set (val) {
        //           this.$emit('input', val);
        //       }
        //   }
      },
      methods: {
          copyServiceUrlHandle () {
            let clipboard = new Clipboard('#btn'); // 获取点击按钮的元素
            /* 注意此事件监听是异步的   */
            clipboard.on('success', (e) => {
                console.info('Text:', e.text);
                e.clearSelection();
                // 释放内存
                clipboard.destroy();
                this.$emit('popupClick');

                // return alert('复制成功：' + e.text);
            });
        }

      }
  };
</script>
  
<style lang="scss" scoped>
@import './guidePopup.scss';
</style>
  