import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

const routerList = [
  {
    path: '/',
    beforeEnter: (to, from, next) => {
      // const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|MicroMessenger|QQ/i.test(
      //   navigator.userAgent
      // );
      // // 根据设备类型进行跳转
      // if (isMobile) {
      //   next('/m_home');
      // } else {
      //   next('/home');
      // }
    },
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('@/views/Home/index'),
    meta: { title: '首页', keepAlive: true, type: 'pc' },
  },
  {
    path: '/m_home',
    name: 'm_home',
    component: () => import('@/views/Mhome/index'),
    meta: { title: '首页', keepAlive: true, type: 'mobile' },
  },
];

const createRouter = () =>
  new Router({
    mode: 'history',
    base: process.env.publicPath,
    scrollBehavior: () => ({ y: 0 }),
    routes: routerList,
  });

const router = createRouter();

export default router;
